import React from "react";
import { Helmet } from "react-helmet"
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";

import imgL from "../assets/image/png/tonythetiger.png";

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Hero
          username="tonythetiger"
          fullname='Tony Sangimino'
          homegym="BeGame, 1300 Old Bayshore Hwy, Burlingame CA 94010"
          pricing={<a>$80 for 1 hour or<br/>$40 for 1/2h</a>}
          profile={`Tony "The Tiger" Sangimino holds a blackbelt in various martial arts such as Judo, Jiu-jitsu and Tae Kwon Do. Reaching national level competition and beyond in various styles
            of combat sports and martial arts. Tony has 24 years of competition experience.`}
          imgL={imgL}
          calendly={"https://calendly.com/tonybegame/30min"}
        />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
